var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800","max-height":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{domProps:{"textContent":_vm._s(("DATE: " + (_vm.item.date)))}}),_c('v-card-text',[_c('GmapMap',{ref:"loc",staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":19,"options":{
						zoomControl: true,
						mapTypeControl: true,
						mapTypeId: 'hybrid',
						scaleControl: true,
						streetViewControl: false,
						rotateControl: false,
						fullscreenControl: true,
						disableDefaultUi: false,
					}}},[_c('gmap-marker',{attrs:{"position":(_vm.markers != null && _vm.markers.length > 0) ? _vm.markers[0].position : 0,"clickable":false,"draggable":false,"icon":{
							url: ('http://maps.google.com/mapfiles/ms/icons/green-dot.png'),
							scaledSize: {width: 50, height: 50, f: 'px', b: 'px'},
						}}})],1),_vm._v(" "+_vm._s(_vm.item.latitude)+" "+_vm._s(_vm.item.longitude)+" ")],1)],1),_c('v-card-actions',{staticClass:"primary"},[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"secondary"},on:{"click":_vm.close}},[_vm._v(" CLOSE ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }