<template>
	<v-dialog
    v-model="dialog"
    max-width="800"
    max-height="600"
		persistent
  >
		<v-card>
			<v-card-title v-text="`DATE: ${item.date}`" />
			<v-card-text>

				<GmapMap
					ref="loc"
					:center="center"
					:zoom="19"
					style="width: 100%; height: 400px"
					:options="{
						zoomControl: true,
						mapTypeControl: true,
						mapTypeId: 'hybrid',
						scaleControl: true,
						streetViewControl: false,
						rotateControl: false,
						fullscreenControl: true,
						disableDefaultUi: false,
					}"
				>
					<gmap-marker
						:position="(markers != null && markers.length > 0) ? markers[0].position : 0"
						:clickable="false"
						:draggable="false"
						:icon="{
							url: ('http://maps.google.com/mapfiles/ms/icons/green-dot.png'),
							scaledSize: {width: 50, height: 50, f: 'px', b: 'px'},
						}"
					/>
				</GmapMap>
				{{item.latitude}} {{item.longitude}}
			</v-card-text>
		</v-card>
		<v-card-actions class="primary">
			<v-spacer></v-spacer>
			<v-btn
				dark
				color="secondary"
				@click="close"
			>
				CLOSE
			</v-btn>
		</v-card-actions>
	</v-dialog>
</template>

<script>
	export default {
		props: {
			item: Object,
			dialog: Boolean,
			center: Object,
			markers: Array,
		},
		data() {
			return {}
		},
		methods: {
			close(){
				this.$emit('closeDialog')
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>